@import 'fikaigo-web-ui-component/style.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --h-navheader: 80px;
  --w-navbar: 240px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer base {
  p {
    @apply text-base leading-8.5;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .text-title {
    @apply text-2xl font-semibold;
  }

  .text-error {
    @apply text-sm font-medium text-danger;
  }
}
